import React, { useEffect, useState, useContext, Suspense } from "react";
// Import React Router
import { useNavigate } from "react-router-dom";
// Import Material UI
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
// Import Context
import AppStatusContext from "../../../contexts/AppStatusContext";
import PageContext from "../../../contexts/PageContext";
// Import Own Components
import Project from "./Project";
import Section from "../../ui/Section";
import Loading from "../../ui/Loading/Loading";
// Import Data
import projectList from "../../../utils/projects-util";

function Projects() {
  // Context
  const { setSelectedPage } = useContext(PageContext);
  const { appStatus } = useContext(AppStatusContext);
  const theme = useTheme();
  const navigate = useNavigate();
  // States
  const [projects, setProjects] = useState(projectList);

  useEffect(() => {
    setSelectedPage("Projects");

    if (appStatus !== "normal" || !appStatus) {
      navigate("/");
    }

    // fetch(`/api/projects`)
    //   .then((res) => res.json())
    //   .then((data) => setProjects(data.projects));
  }, [appStatus, navigate, setSelectedPage]);

  console.log(projects);

  return (
    <Suspense fallback={<Loading />}>
      {projects.map((projectCat, catIdx) => (
        <Section
          className="project-section"
          // style={}
          key={catIdx}
        >
          {catIdx > 0 && <Divider sx={{ mb: "1.5rem" }} />}
          <Typography
            component="h2"
            sx={{
              width: "max-content",
              fontSize: { xs: "1.125rem", sm: "1.2rem", lg: "1.25rem" },
              fontWeight: "700",
              borderRadius: "3px",
              marginBottom: "1rem",
            }}
          >
            {projectCat.catName}
          </Typography>

          <Grid container spacing={4}>
            {projectCat.project.map((project) => (
              <Project
                desc={project.desc}
                title={project.title}
                thumbnail={project.thumbnail}
                href={project.url}
                theme={theme}
                key={project.href}
              />
            ))}
          </Grid>
        </Section>
      ))}
    </Suspense>
  );
}

export default Projects;
