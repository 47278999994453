import React from "react";
// Import React Router
import { Link } from "react-router-dom";
// Import MUI Components
import Icon from "@mui/material/Icon";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
// Import Styles
import classes from "./linkItem.module.css";

function LinkItem({ link }) {
  const theme = useTheme();

  return (
    <ListItem
      sx={{
        p: 0,
        width: "max-content",
      }}
      className={classes.listItem}
    >
      <ListItemButton
        sx={{
          width: "auto",
          borderRadius: "5px",
          p: 0,
          pl: "1rem",
          pr: "1rem",
          flexGrow: 0,
        }}
        onClick={() => {
          window.location.href = link.link;
        }}
      >
        <Icon
          sx={{
            fontSize: "2rem",
            width: "max-content",
            height: "max-content",
            mr: "1rem",
            color: theme.palette.mode === "dark" ? "white" : "neutral.800",
          }}
        >
          {link.icon}
        </Icon>

        <ListItemText sx={{ width: "auto" }}>
          <Typography
            component={Link}
            // to={link.link}
            className={classes.linkItem}
            sx={{
              width: "fit-content !important",
              fontSize: "1rem",
              fontWeight: "600",
              color: "secondary.main",
              textDecorationColor: "secondary.main",
            }}
          >
            {link.title}
          </Typography>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
}

export default LinkItem;
