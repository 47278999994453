import React from "react";
// Import Context
import { PageContextProvider } from "../../../contexts/PageContext";
import { ThemeColorModeContextProvider } from "../../../contexts/ThemeColorModeContext";
// Import Own Components
import Nav from "./nav/Nav";
import Footer from "./footer/Footer";

function HeaderFooter(props) {
  return (
    <PageContextProvider>
      <ThemeColorModeContextProvider>
        <Nav />
        {props.children}
        <Footer />
      </ThemeColorModeContextProvider>
    </PageContextProvider>
  );
}

export default HeaderFooter;
