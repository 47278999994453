import React from "react";
// Import MUI Components
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
// Import Own Components
import Section from "../../../ui/Section";

function AboutMe() {
  const theme = useTheme();

  return (
    <Section className="about-me-section" delay={0.2}>
      <Typography
        component="h1"
        sx={{
          width: "max-content",
          fontSize: { xs: "1.125rem", sm: "1.2rem", lg: "1.25rem" },
          borderBottom: "4px solid",
          borderColor:
            theme.palette.mode === "dark"
              ? "rgba(255, 255, 255, 0.22)"
              : "hsl(30, 6%, 43%)",
          borderRadius: "3px",
          marginBottom: "1rem",
        }}
      >
        About Me
      </Typography>

      <Typography
        component="p"
        sx={{
          textIndent: "1em",
          fontSize: "1rem",
          wordBreak: "break-word",
          fontWeight: "400",
        }}
      >
        Joshua is a software developer with a passion to build websites, mobile
        app that helps people. He loves walking around during free time.
        Currently, he pursues a BCS in Software Engineering in Malaysia.
      </Typography>
    </Section>
  );
}

export default AboutMe;
