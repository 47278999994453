import React, { useContext } from "react";
// Import MUI Components
import useTheme from "@mui/material/styles/useTheme";
import IconButton from "@mui/material/IconButton";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
// Import Framer Motion
import { AnimatePresence, motion } from "framer-motion";
// Import Context
import ThemeColorModeContext from "../../../../contexts/ThemeColorModeContext";

function ThemeMode() {
  const theme = useTheme();
  const colorMode = useContext(ThemeColorModeContext);

  return (
    <AnimatePresence mode="wait" initial={true}>
      <motion.div
        key={theme.palette.mode}
        // whileHover={{
        //   scale: [1, 1.1, 1.1, 1],
        //   rotate: [0, 0, 720, 720],
        //   borderRadius: ["0%", "0%", "0%", "0%"],
        // }}
        initial={{
          scale: [1, 1.2, 1],
          rotate: [0, 360, 360],
          borderRadius: ["0%", "50%", "0%"],
          opacity: 0,
        }}
        animate={{
          scale: 1,
          rotate: 0,
          borderRadius: "0%",
          opacity: 1,
        }}
        exit={{
          scale: [1, 1.2, 1],
          rotate: [0, 360, 360],
          borderRadius: ["0%", "50%", "0%"],
          opacity: 0,
        }}
        transition={{
          duration: 0.5,
          times: [0, 0.5, 1],
        }}
      >
        <IconButton
          onClick={colorMode.toggleColorMode}
          color="inherit"
          sx={{
            mr: { xxs: "0.2rem", xs: "0.4rem" },
            borderRadius: "5px",
            backgroundColor:
              theme.palette.mode === "dark" ? "primary.main" : "secondary.main",
            "&:hover": {
              backgroundColor:
                theme.palette.mode === "dark"
                  ? "primary.dark"
                  : "secondary.dark",
            },
          }}
        >
          {theme.palette.mode === "dark" ? <LightModeIcon /> : <DarkModeIcon />}
        </IconButton>
      </motion.div>
    </AnimatePresence>
  );
}

export default ThemeMode;
