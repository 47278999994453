import React from "react";
// Import Material UI
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
// Import Own Components
import Section from "../../../ui/Section";

function Profile() {
  return (
    <Section
      className="profile-section"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        width: "fit-content",
      }}
      delay={0.1}
    >
      <Box
        component="img"
        src="../../../../img/author_pp.jpg"
        alt="Profile Pic"
        sx={{
          height: "96px",
          width: "96px",
          border: "2px solid rgba(255, 255, 255, 0.92)",
          borderRadius: "50%",
        }}
      ></Box>
      <Box
        component="div"
        sx={{
          ml: { xxs: "0.65rem", xs: "1.25rem", md: "2rem" },
          textAlign: "right",
          width: "fit-content",
        }}
      >
        <Typography
          component="h2"
          sx={{
            fontSize: { xxs: "1.5rem", sm: "1.875", md: "2.25rem" },
            fontWeight: "700",
            fontFamily: "'M PLUS Rounded 1c', sans-serif",
          }}
        >
          Joshua Koh Min En
        </Typography>
        <Typography
          component="p"
          sx={{
            display: { xxs: "block", md: "inline" },
            fontSize: { xs: "1rem", sm: "1rem", lg: "1.125rem" },
          }}
        >
          Software Engineer&nbsp;
        </Typography>
        <Typography
          component="p"
          sx={{
            display: { md: "block", lg: "inline" },
            fontSize: "1rem",
          }}
        >
          ( Frontend Web Developer )
        </Typography>
      </Box>
    </Section>
  );
}

export default Profile;
