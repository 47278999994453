import React from "react";
// Import MUI Components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

function Footer() {
  const currentYear = new Date().getFullYear();
  const theme = useTheme();

  return (
    <Box
      component="footer"
      sx={{
        width: { xxs: "80vw", xs: "85vw", sm: "60vw", md: "58vw", lg: "35vw" },
        maxWidth: "60ch",
        margin: "auto",
        marginTop: "1rem",
        pb: "2rem",
      }}
    >
      <Typography
        component="small"
        sx={{
          display: "block",
          width: "100%",
          color:
            theme.palette.mode === "dark"
              ? theme.palette.text.disabled
              : "neutral.350",
          textAlign: "center",
          fontSize: "0.875rem",
          fontWeight: "400",
        }}
      >
        &copy; Copyright {currentYear} Joshua KME. All rights reserved.
      </Typography>
    </Box>
  );
}

export default Footer;
