import React from "react";
// Import MUI Components
import Typography from "@mui/material/Typography";
import GitHubIcon from "@mui/icons-material/GitHub";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import useTheme from "@mui/material/styles/useTheme";
// Import Own Components
import Section from "../../../ui/Section";
import LinkItem from "./LinkItem";

function Links() {
  const theme = useTheme();

  const linksArr = [
    {
      title: "@Joshuakme",
      link: "https://github.com/Joshuakme",
      icon: <GitHubIcon />,
    },
    {
      title: "@josh_ennn33",
      link: "https://twitter.com/josh_ennn33",
      icon: <TwitterIcon />,
    },
    {
      title: "@Joshua Koh Min En",
      link: "www.linkedin.com/in/joshua-koh-min-en-173436228",
      icon: <LinkedInIcon />,
    },
  ];

  return (
    <Section className="links-section" delay={0.4}>
      <Typography
        component="h1"
        sx={{
          width: "max-content",
          fontSize: { xs: "1.125rem", sm: "1.2rem", lg: "1.25rem" },
          borderBottom: "4px solid",
          borderColor:
            theme.palette.mode === "dark"
              ? "rgba(255, 255, 255, 0.22)"
              : "hsl(30, 6%, 43%)",
          borderRadius: "3px",
          marginBottom: "1rem",
        }}
      >
        Links
      </Typography>

      {linksArr.map((link) => (
        <LinkItem link={link} key={link.link} />
      ))}
    </Section>
  );
}

export default Links;
