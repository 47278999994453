import React from "react";
// Import MUI Components
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import useTheme from "@mui/material/styles/useTheme";

function Hamburger({ open, handleToggle }) {
  const theme = useTheme();

  return (
    <IconButton
      id="hamburger-button"
      aria-label="open drawer"
      aria-controls={open ? "nav-menu" : undefined}
      aria-haspopup="true"
      aria-expanded={open ? "true" : undefined}
      edge="start"
      onClick={handleToggle}
      sx={{
        height: "40px",
        width: "40px",
        mr: 1,
        border: "1px solid",
        borderColor:
          theme.palette.mode === "dark" ? "customWhite.low" : "neutral.900",
        borderRadius: "5px",
      }}
    >
      <MenuIcon />
    </IconButton>
  );
}

export default Hamburger;
