import React from "react";
// Import React Router
import { Link } from "react-router-dom";
// Import MUI Components
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

function NavMenu({
  anchorEl,
  navItems,
  handleClose,
  handleListKeyDown,
  open,
  theme,
}) {
  return (
    // <Menu
    //   id="nav-menu"
    //   anchorReference="anchorPosition"
    //   anchorPosition={{ top: 64, left: "" }}
    //   open={open}
    //   onClose={handleClose}
    //   autoFocusItem={open}
    //   MenuListProps={{
    //     "aria-labelledby": "nav-button",
    //   }}
    //   onKeyDown={handleListKeyDown}
    // >
    <Menu
      id="nav-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      autoFocusItem={open}
      MenuListProps={{
        "aria-labelledby": "nav-button",
      }}
      onKeyDown={handleListKeyDown}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{
        transform: "translate(0, 16px)",
      }}
    >
      {navItems.map((item) => (
        <Typography
          component={Link}
          to={item.link}
          key={item.title}
          sx={{ textDecoration: "none" }}
        >
          <MenuItem
            onClick={handleClose}
            sx={{
              // backgroundColor:
              //   theme.palette.mode === "dark"
              //     ? "neutral.850"
              //     : "neutral.disabled",
              color: theme.palette.text.primary,
            }}
          >
            {item.title}
          </MenuItem>
        </Typography>
      ))}
    </Menu>
  );
}

export default NavMenu;
