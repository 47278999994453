import React from "react";
// Import MUI Components
import Typography from "@mui/material/Typography";

function Maintainance() {
  return (
    <section>
      <Typography variant="h1">Maintainance</Typography>
    </section>
  );
}

export default Maintainance;
