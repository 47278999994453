// Import React Components
import { createContext, useState, useMemo } from "react";
// Import MUI Components
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// Import Own Components
// import Theme from "../utils/Theme";

const ThemeColorModeContext = createContext({
  toggleColorMode: () => {},
});

export function ThemeColorModeContextProvider(props) {
  // States
  const [themeMode, setThemeMode] = useState("dark");

  const context = useMemo(
    () => ({
      toggleColorMode: () => {
        setThemeMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  function getDesignTokens(themeMode) {
    return {
      palette: {
        ...(themeMode === "light"
          ? {
              //  Light Mode
              primary: {
                light: "#ec775f",
                main: "#ed7f68",
                dark: "#b8503d",
              },
              secondary: {
                light: "#1993a3",
                main: "#1993a3",
                dark: "#006675",
              },
              background: {
                default: "#e5e5e5",
                paper: "#e5e5e5",
              },
              neutral: {
                disabled: "#ffffff80",
                900: "#1e1e1e", // 01dp
                800: "#252525", // 03dp
                500: "#383838", // 24dp
                350: "#797979",
                200: "#ffffffb3",
                100: "#fff",
              },
            }
          : {
              // Dark Mode
              primary: {
                light: "#ffa78c",
                main: "hsl(10, 80%, 65%)",
                dark: "#b64734",
              },
              secondary: {
                light: "#66ffe7",
                main: "hsl(170, 86%, 45%)",
                dark: "#00a385",
              },
              background: {
                default: "#1e1e1e",
                paper: "#1e1e1e",
              },
              neutral: {
                950: "#121212", // 00dp
                945: "#12121250", // with 0.5 opacity
                900: "#1e1e1e", // 01dp
                895: "#1e1e1e50",
                850: "#232323", // 02dp
                845: "#23232350",
                800: "#252525", // 03dp
                750: "#272727", // 04dp
                700: "#2c2c2c", // 06dp
                650: "#2f2f2f", // 08dp
                600: "#333333", // 12dp
                550: "#353535", // 16dp
                500: "#383838", // 24dp
                450: "#ffffff80", // Grey
              },
              customWhite: {
                high: "#fafafa",
                medium: "#fafaf",
                low: "#e7e7e7B3",
              },
            }),
        mode: themeMode,
      },
      typography: {
        fontFamily: [
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
      breakpoints: {
        values: {
          xxs: 0,
          xs: 240,
          sm: 480,
          md: 768,
          lg: 992,
          xl: 1280,
          "2xl": 1536,
        },
      },
    };
  }

  const theme = useMemo(
    () => createTheme(getDesignTokens(themeMode)),
    [themeMode]
  );

  return (
    <ThemeColorModeContext.Provider value={context}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </ThemeColorModeContext.Provider>
  );
}

export default ThemeColorModeContext;
