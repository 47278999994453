import React from "react";
// Import Material UI
import Box from "@mui/material/Box";

function StyledCard(props) {
  return (
    <Box
      sx={{
        width: "fit-content",
        // height: "235px",
        // backgroundColor: "white",
        textAlign: "center",
      }}
    >
      {props.children}
    </Box>
  );
}

export default StyledCard;
