// Import Framer Motion
import { motion } from "framer-motion";
// Import Own Components
import Backdrop from "../Backdrop";

function Modal({ handleClose, text }) {
  const dropIn = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 0.1,
        type: "spring",
        damping: 25,
        stiffness: 500,
      },
    },
    exit: {
      y: "100vh",
      opacity: 0,
    },
  };

  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className="modal orange-gradient"
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        style={{
          width: "clamp(50%, 700px, 90%)",
          height: "min(50%, 300px)",

          margin: "auto",
          padding: "0 2rem",
          borderRadius: "12px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          background: "linear-gradient(10deg, #ffaa00, #ff6a00)",
        }}
      >
        <p>{text}</p>
        <button onClick={handleClose}>Close</button>
      </motion.div>
    </Backdrop>
  );
}

export default Modal;
