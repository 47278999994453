import React from "react";
// Import React Router
import { Link } from "react-router-dom";
// Import MUI Components
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
// Import Framer Motion
import { motion } from "framer-motion";

function NavItem({ item, selectedPage, setSelectedPage }) {
  const theme = useTheme();

  return (
    <ListItem
      component={Link}
      to={item.link}
      sx={{ ml: "0.5rem", p: 0 }}
      onClick={() => {
        setSelectedPage(item.title);
      }}
    >
      <ListItemButton
        key={item.title}
        sx={{
          color: "#fff",
          borderRadius: "5px",
          pt: 0,
          pb: 0,
          pl: "0.5rem",
          pr: "0.5rem",
        }}
      >
        <ListItemText>
          <Typography
            sx={{
              fontSize: { xs: "1rem", sm: "1rem", lg: "1.125rem" },
              color: theme.palette.text.primary,
              textDecoration: "none",
            }}
          >
            {item.title}
          </Typography>
        </ListItemText>
        {item.title === selectedPage ? (
          <Box
            component={motion.div}
            layoutId="underline"
            sx={{
              position: "absolute",
              bottom: "-1px",
              left: 0,
              right: 0,
              height: "1px",
              backgroundColor: "primary.main",
            }}
          />
        ) : null}
      </ListItemButton>
    </ListItem>
  );
}

export default NavItem;
