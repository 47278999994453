import React from "react";
// Import React Router
import { Link } from "react-router-dom";
// Import MUI Components
import Typography from "@mui/material/Typography";
import Timeline from "@mui/lab/Timeline";
import useTheme from "@mui/material/styles/useTheme";
// Import Own Components
import BioRow from "./bioRow";
import Section from "../../../ui/Section";
// Import Styles
import classes from "./bio.module.css";

function Bio() {
  const theme = useTheme();

  const bioDataArr = [
    { year: "2002", desc: "Born in Sabah, Malaysia." },
    {
      year: "2021",
      desc: (
        <>
          Completed Foundation in Science at&nbsp;
          <Typography
            component={Link}
            onClick={() => {
              window.location.href = "https://www.xmu.edu.my";
            }}
            // to="https://www.xmu.edu.my"
            className={classes.bioLink}
            sx={{
              color:
                theme.palette.mode === "dark"
                  ? theme.palette.primary.main
                  : theme.palette.primary.dark,
            }}
          >
            Xiamen University Malaysia
          </Typography>
          &nbsp;(XMUM).
        </>
      ),
    },
    {
      year: "2022",
      desc: (
        <>
          Pursuing BCS in Software Engineering at&nbsp;
          <Typography
            component={Link}
            onClick={() => {
              window.location.href = "https://www.tarc.edu.my";
            }}
            // to="https://www.tarc.edu.my"
            className={classes.bioLink}
            sx={{
              color:
                theme.palette.mode === "dark"
                  ? theme.palette.primary.main
                  : theme.palette.primary.dark,
            }}
          >
            Tunku Abdul Rahman University College
          </Typography>
          &nbsp;(TAR UC).
        </>
      ),
    },
    {
      year: "Present",
      desc: (
        <>
          Pursuing BCS in Software Engineering at&nbsp;
          <Typography
            component={Link}
            onClick={() => {
              window.location.href = "https://www.tarc.edu.my";
            }}
            // to="https://www.tarc.edu.my"
            className={classes.bioLink}
            sx={{
              color:
                theme.palette.mode === "dark"
                  ? theme.palette.primary.main
                  : theme.palette.primary.dark,
            }}
          >
            Tunku Abdul Rahman University of Management and Technology
          </Typography>
          &nbsp;(TAR UMT).
        </>
      ),
    },
  ];

  return (
    <Section className="bio-section" delay={0.3}>
      <Typography
        component="h1"
        sx={{
          width: "max-content",
          fontSize: { xs: "1.125rem", sm: "1.2rem", lg: "1.25rem" },
          borderBottom: "4px solid",
          borderColor:
            theme.palette.mode === "dark"
              ? "rgba(255, 255, 255, 0.22)"
              : "hsl(30, 6%, 43%)",
          borderRadius: "3px",
          marginBottom: "1rem",
        }}
      >
        Bio
      </Typography>

      <Timeline position="right" className={classes.bioTimeline}>
        {bioDataArr.map((bioData) => (
          <BioRow year={bioData.year} desc={bioData.desc} key={bioData.year} />
        ))}
      </Timeline>
    </Section>
  );
}

export default Bio;
