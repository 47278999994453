import React, { useState, useContext } from "react";
// Import MUI Components
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import useTheme from "@mui/material/styles/useTheme";
// Import Context
import PageContext from "../../../../contexts/PageContext";
// Import Own Components
import Logo from "./logo/Logo";
import NavMenu from "./NavMenu";
import NavItem from "./nav-item/NavItem";
import ThemeMode from "./ThemeMode";
import Hamburger from "./Hamburger";

const navItems = [
  { title: "Projects", link: "/projects" },
  { title: "Posts", link: "/posts" },
];

function Nav() {
  // States
  const pageCtx = useContext(PageContext);
  const [anchorEl, setAnchorEl] = useState(null);
  let open = Boolean(anchorEl);
  const theme = useTheme();

  const handleToggle = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      open = true;
    } else if (event.key === "Escape") {
      open = false;
    }
  }

  return (
    <AppBar
      position="sticky"
      component="nav"
      sx={{
        width: "100%",
        minWidth: "260px",
        marginBottom: "2.25rem",
        backgroundColor:
          theme.palette.mode === "dark" ? "neutral.845" : "neutral.disabled",
        backdropFilter: "blur(10px)",
        zIndex: 2,
      }}
    >
      <Toolbar>
        <Container
          sx={{
            flex: 1,
            w: "100%",
            maxWidth: "48rem !important",
            p: "0.5rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Logo
            theme={theme}
            selectedPage={pageCtx.selectedPage}
            setSelectedPage={pageCtx.setSelectedPage}
          />

          <Stack
            direction={{ xxs: "column", md: "row" }}
            sx={{
              display: { xxs: "none", md: "flex" },
              width: { xxs: "100%", md: "auto" },
              alignItems: "center",
              flexGrow: 1,
              mt: { xxs: 4, sm: 0 },
            }}
          >
            <Box sx={{ display: { xxs: "none", sm: "flex" } }}>
              {navItems.map((item) => (
                <NavItem
                  key={item.title}
                  item={item}
                  selectedPage={pageCtx.selectedPage}
                  setSelectedPage={pageCtx.setSelectedPage}
                />
              ))}
            </Box>
          </Stack>

          <Box
            sx={{
              display: "flex",
              textAlign: "right",
            }}
          >
            <Box>
              <ThemeMode />
            </Box>
            {/* Hamburger */}
            <Box sx={{ ml: 2, display: { xxs: "inline-block", md: "none" } }}>
              <Hamburger open={open} handleToggle={handleToggle} />
              <NavMenu
                anchorEl={anchorEl}
                handleClose={handleClose}
                handleListKeyDown={handleListKeyDown}
                navItems={navItems}
                open={open}
                theme={theme}
              />
            </Box>
          </Box>
        </Container>
      </Toolbar>
    </AppBar>
  );
}

export default Nav;
