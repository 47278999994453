// Import React Components
import { useEffect, useState, useContext, useCallback } from "react";
// Import Context
import AppStatusContext from "../../contexts/AppStatusContext";
// Import Material UI
import Box from "@mui/material/Box";
// Import Own Components
import CommingSoon from "./comming-soon/CommingSoon";
import Maintainance from "./maintainance/Maintainance";
import Home from "./home/Home";

function App() {
  let header = document.querySelector("nav");
  let footer = document.querySelector("footer");
  // Context
  const appStatusCtx = useContext(AppStatusContext);
  // State
  const [app, setApp] = useState(<Home />);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);

  // Methods
  // Check WebSite Display Status
  const checkAppStatus = useCallback(() => {
    if (appStatusCtx.appStatus === "coming soon") {
      setApp(<CommingSoon />);
    } else if (appStatusCtx.appStatus === "maintainance") {
      setApp(<Maintainance />);
    } else if (appStatusCtx.appStatus === "normal") {
      setApp(<Home />);
    }
  }, [appStatusCtx.appStatus]);

  useEffect(() => {
    checkAppStatus();

    if (header && footer) {
      setHeaderHeight(header.clientHeight);
      setFooterHeight(footer.clientHeight);
    }
  }, [footer, checkAppStatus, header]);

  return (
    <Box
      component="section"
      sx={{
        minHeight: `calc(100vh - 2.25rem - ${headerHeight}px - ${footerHeight}px - 1rem)`,
      }}
    >
      {app}
    </Box>
  );
}

export default App;
