export default [
  // {
  //   catName: "Personal Projects",
  //   project: [
  //     {
  //       title: "Mashair Beauty",
  //       thumbnail: `./img/projects/mashair_beauty.jpg`,
  //       href: "mashair",
  //       desc: "A website that provide booking service for customers in Hong Kong to reserve the service (Salon, Massage, Beauty)",
  //       url: "https://mashair-rebuild.vercel.app",
  //     },
  //   ],
  // },
  {
    catName: "Uni Projects",
    project: [
      {
        title: "JLWSports",
        thumbnail: `./img/projects/jlws_website.jpg`,
        href: "jlwsports",
        desc: "A website that sells sports-related products online which include tops & bottoms, shoes, fitness instrument & etc",
        url: "https://jlwsportsmy.netlify.app",
      },
      {
        title: "Crystalify",
        thumbnail: `./img/projects/concert_ticketing_system.jpg`,
        href: "concert-ticketing-system",
        desc: "A console-based concert ticketing system that provides concert ticket ordering service to user to purchase their desired concert",
        url: "https://github.com/Joshuakme/Concert-Ticketing-System",
      },
      {
        title: "Idol Voting",
        thumbnail: `./img/projects/idol_voting.jpg`,
        href: "idol-voting",
        desc: "A console-based idol voting system that implements the Java ADT Classes in building the program",
        url: "https://github.com/Joshuakme/IdolVoting",
      },
      {
        title: "FTF GymWeb",
        thumbnail: `./img/projects/ftf_gymweb.png`,
        href: "ftf-gymweb",
        desc: "A gym website built using ASP.NET with SQL as the database.",
        url: "https://github.com/Joshuakme/FTFGymWeb",
      },
      {
        title: "XFund",
        thumbnail: `./img/projects/xfund.png`,
        href: "xfund",
        desc: "XFund is a powerful mobile application committed to making a difference by addressing one of the world's most pressing challenges: eradicating poverty.",
        url: "https://github.com/Joshuakme/XFund-Fundraising_Community",
      },
    ],
  },
];
