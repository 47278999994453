import React from "react";
// Import Material UI
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import { Link } from "@mui/material";
// Import Third Party Components
import ReactMarkdown from "react-markdown";
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/cjs/styles/prism";
import js from "react-syntax-highlighter/dist/cjs/languages/prism/javascript";
import css from "react-syntax-highlighter/dist/cjs/languages/prism/css";
import remarkGfm from "remark-gfm";
// Import Own Components
//import PostHeader from "./post-header";

export default function PostContent({ post }) {
  const theme = useTheme();
  const imagePath = `/images/posts/${post.slug}/${post.image}`;

  const customRenderers = {
    // img: (image) => {
    //   return (
    //     <Image
    //       src={`/images/posts/${post.slug}/${image.properties.src}`}
    //       alt={image.alt}
    //       width={600}
    //       height={300}
    //     />
    //   );
    // },
    h1: (text) => {
      const { children } = text;

      return <Typography variant="h2">{children}</Typography>;
    },
    h2: (text) => {
      const { children } = text;

      return <Typography variant="h4">{children}</Typography>;
    },
    h3: (text) => {
      const { children } = text;

      return <Typography variant="h5">{children}</Typography>;
    },
    p: (paragraph) => {
      const { node } = paragraph;
      if (node.children[0].tagName === "img") {
        const image = node.children[0];
        return (
          <Box sx={{ margin: "1rem auto", width: "100%", maxWidth: "600px" }}>
            <Box
              component="img"
              src={`/images/posts/${post.slug}/${image.properties.src}`}
              alt={image.properties.alt}
              width={600}
              height={300}
            />
          </Box>
        );
      }

      return (
        <Typography
          component="p"
          sx={{
            color: theme.palette.mode === "dark" ? "customWhite.low" : "",
          }}
        >
          {paragraph.children}
        </Typography>
      );
    },
    li: (list) => {
      return (
        <Typography
          component="li"
          sx={{ color: theme.palette.mode === "dark" ? "customWhite.low" : "" }}
        >
          {list.children}
        </Typography>
      );
    },
    a: (link) => {
      const { href, children } = link;
      return (
        <Link
          href={href}
          sx={{
            color:
              theme.palette.mode === "dark"
                ? theme.palette.primary.main
                : theme.palette.primary.dark,
            textDecorationColor: "secondary.main",
          }}
        >
          {children}
        </Link>
      );
    },
    br: (lineBreak) => {
      return (
        <Box sx={{ height: "1rem", width: "100%" }}>{lineBreak.children}</Box>
      );
    },
    code: (code) => {
      const { className, children } = code;
      const language = className.split("-")[1]; // className is something like language-js => We need the "js" part here

      return (
        <SyntaxHighlighter style={atomDark} language={language}>
          {children}
        </SyntaxHighlighter>
      );
    },
  };

  return (
    <Box
      component="article"
      sx={{
        width: "95%",
        maxWidth: "60rem",
        margin: "2rem auto",
        fontSize: "1.25rem",
        lineHeight: "2rem",
        backgroundColor:
          theme.palette.mode === "dark" ? "neutral.750" : "neutral.disabled",
        backdropFilter: "blur(10px)",
        borderRadius: "6px",
        padding: "1rem",
      }}
    >
      {/* <PostHeader title={post.title} image={imagePath} /> */}
      <ReactMarkdown components={customRenderers} remarkPlugins={[remarkGfm]}>
        {post.content}
      </ReactMarkdown>
    </Box>
  );
}
