import React from "react";
// Import MUI Components
import Box from "@mui/material/Box";
// Import Style
import classes from "./comingSoon.module.css";

function CommingSoon() {
  return (
    <Box
      sx={{
        minHeight: { lg: "calc(100vh - 20vh)", xxs: "calc(100vh - 20vh)" },
      }}
    >
      <Box className={classes.mainDiv}>
        <h2>JoshuaKME</h2>
        <p>Website is coming soon. </p>
        <p>
          I am <span>booming around</span> and working on the full version of my
          new site and will be back shortly(probably).
        </p>
        <a href="mailto:joshuakoh23@gmail.com">
          Contact Owner to build faster...
        </a>
      </Box>
    </Box>
  );
}

export default CommingSoon;
