import React, { useEffect, useState, useContext } from "react";
// Import React Router
import { useParams, useNavigate } from "react-router-dom";
// Import Material UI
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
// Import Context
import AppStatusContext from "../../../contexts/AppStatusContext";
import PageContext from "../../../contexts/PageContext";
// Import Own Components
import PostContent from "../posts/post-detail/PostContent";

function PostItem() {
  // Context
  const { setSelectedPage } = useContext(PageContext);
  const { appStatus } = useContext(AppStatusContext);
  const theme = useTheme();
  const navigate = useNavigate();
  let { postSlug } = useParams();
  // Variable
  const [post, setPost] = useState({});

  useEffect(() => {
    setSelectedPage("Post");

    if (appStatus !== "normal" || !appStatus) {
      navigate("/");
    }

    fetch(`http://localhost:8000/api/post/${replaceInvalidChar(postSlug)}`)
      .then((res) => res.json())
      .then((data) => setPost(data.post));
  }, [appStatus, navigate, postSlug, setSelectedPage]);

  // Methods

  function replaceInvalidChar(input) {
    const replaceHashtag = "%23";
    const replaceSpace = "%20";
    let validString;

    validString = input.replace("#", replaceHashtag).replace(" ", replaceSpace);

    return validString;
  }

  return (
    <Box>
      <PostContent post={post} />
    </Box>
  );
}

export default PostItem;
