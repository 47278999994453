import React, { Suspense, useContext, useEffect } from "react";
// Import MUI Components
import Box from "@mui/material/Box";
// Import Context
import PageContext from "../../../contexts/PageContext";
// Import Own Components
import AboutMe from "./about-me/AboutMe";
import Bio from "./bio/Bio";
import Links from "./links/Links";
import Profile from "./profile/Profile";
import Loading from "../../ui/Loading/Loading";

function Home() {
  // Context
  const { setSelectedPage } = useContext(PageContext);

  useEffect(() => {
    setSelectedPage("Home");
  });

  return (
    <Suspense fallback={<Loading />}>
      <Box component="section" className="home-section">
        <Profile />
        <AboutMe />
        <Bio />
        <Links />
      </Box>
    </Suspense>
  );
}

export default Home;
