import React, { useContext, useState } from "react";
// Import Framer Motion
import { motion } from "framer-motion";
// Import Context
import ThemeColorModeContext, {
  ThemeColorModeContextProvider,
} from "../../../contexts/ThemeColorModeContext";
// Import Component
import Modal from "../../ui/Modal";
import Loading from "../../ui/Loading/Loading";

function Test() {
  // Context
  const colorModeCtx = useContext(ThemeColorModeContext);
  // State
  const [modalOpen, setModalOpen] = useState(false);

  const close = () => setModalOpen(false);
  const open = () => setModalOpen(true);

  return (
    <ThemeColorModeContextProvider>
      <h2>Test</h2>
      <button onClick={colorModeCtx.toggleColorMode}>Change</button>
      {/* 
      {dummyExpenses.map((record) => (
        <div key={record.id}>
          <h2>{record.account}</h2>
          <p style={{ display: "inline" }}>{record.description}</p>
          <p style={{ display: "inline" }}>&nbsp;{record.amount}</p>
        </div>
      ))} */}
      <motion.button
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        className="save-button"
        onClick={() => (modalOpen ? close() : open())}
      >
        Launch modal
      </motion.button>

      {modalOpen && <Modal modalOpen={modalOpen} handleClose={close} />}

      <Loading />
    </ThemeColorModeContextProvider>
  );
}

export default Test;
