import { createContext, useState } from "react";

const PageContext = createContext({
  selectedPage: "Home",
  setSelectedPage: () => {},
});

export function PageContextProvider(props) {
  const [selectedPage, setSelectedPage] = useState();

  const context = {
    selectedPage: selectedPage,
    setSelectedPage: setSelectedPage,
  };

  return (
    <PageContext.Provider value={context}>
      {props.children}
    </PageContext.Provider>
  );
}

export default PageContext;
