import React from "react";
// Import React Router Components
import { Routes, Route, useLocation } from "react-router-dom";
// Import Framer Motion
import { AnimatePresence } from "framer-motion";
// Import Context
import { AppStatusContextProvider } from "../contexts/AppStatusContext";
// Import Own Components
import {
  Admin,
  App,
  NotFoundPage,
  Posts,
  Projects,
  PostItem,
} from "../utils/ComponentsHelper";
import Test from "../components/app/home/Test";

function AnimatedRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait" initial={true}>
      <AppStatusContextProvider>
        {/* Routes */}
        <Routes location={location} key={location.pathname}>
          <Route exact path="/" element={<App />} />
          <Route path="/test" element={<Test />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/posts" element={<Posts />} />
          <Route exact path="/post/:postSlug" element={<PostItem />} />
          <Route exact path="/admin" element={<Admin />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </AppStatusContextProvider>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;
