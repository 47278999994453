import React, { useContext, useEffect } from "react";
// Import React Router
import { Link } from "react-router-dom";
// Import MUI Components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
// Import Framer Motion Components
import { motion } from "framer-motion";
// Import Styles
import styled from "@emotion/styled";

function Logo({ theme, selectedPage, setSelectedPage }) {
  const LogoBox = styled.span`
    position: relative;
    display: flex;
    margin-left: 0.5rem;
    margin-right: 1.25rem;

    img {
      transform: rotate(-15deg);
      transition: transform 200ms ease;
    }

    &:hover img {
      transform: rotate(0);
    }
  `;

  return (
    <Link to="/" style={{ textDecoration: "none" }}>
      <LogoBox className="logo-container">
        <Box
          component={motion.img}
          src="../../../../logo.png"
          alt="JK Logo"
          aria-label="logo"
          sx={{
            display: "inline-block",
            marginRight: "0.2rem",
            height: "20px",
            width: "20px",
            filter: theme.palette.mode === "dark" ? "" : "invert(1)",
          }}
        />

        <Typography
          variant="h1"
          sx={{
            width: "max-content",
            flexGrow: 1,
            color:
              theme.palette.mode === "dark"
                ? "customWhite.high"
                : "neutral.900",
            fontSize: "1.125rem",
            fontFamily: "'M PLUS Rounded 1c', sans-serif",
            fontWeight: "700",
            wordBreak: "keep-all",
            letterSpacing: "-1px",
          }}
        >
          Joshua KME
        </Typography>
      </LogoBox>
    </Link>
  );
}

export default Logo;
