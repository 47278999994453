import React, { useEffect, useState, useContext } from "react";
// Import React Router
import { useNavigate } from "react-router-dom";
// Import Material UI
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
// Import Context
import AppStatusContext from "../../../contexts/AppStatusContext";
import PageContext from "../../../contexts/PageContext";
// Import Own Components
import Post from "./Post";
import Section from "../../ui/Section";

function Posts() {
  // Context
  const { setSelectedPage } = useContext(PageContext);
  const { appStatus } = useContext(AppStatusContext);
  const theme = useTheme();
  const navigate = useNavigate();
  // States
  const [allPost, setAllPosts] = useState([]);

  useEffect(() => {
    setSelectedPage("Posts");

    if (appStatus !== "normal" || !appStatus) {
      navigate("/");
    }

    fetch(`/api/posts`)
      .then((res) => res.json())
      .then((data) => setAllPosts(data.posts));
  }, [appStatus, navigate, setSelectedPage]);

  return (
    <Section>
      {/* {catIdx > 0 && <Divider sx={{ mb: "1.5rem" }} />} */}
      <Typography
        component="h2"
        sx={{
          width: "max-content",
          fontSize: { xs: "1.125rem", sm: "1.2rem", lg: "1.25rem" },
          fontWeight: "700",
          borderRadius: "3px",
          marginBottom: "1rem",
        }}
      >
        Posts
      </Typography>

      <Grid container spacing={4}>
        {allPost.map((post) => (
          <Post post={post} theme={theme} key={post.slug} />
        ))}
      </Grid>
    </Section>
  );
}

export default Posts;
