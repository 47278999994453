import React from "react";
// Import MUI Components
import Box from "@mui/material/Box";
// Import Framer Motion Components
import { motion } from "framer-motion";

function Section({ children, className, delay = 0, style = {} }) {
  const motionVariants = {
    hidden: { opacity: 0, x: 0, y: 10 },
    enter: { opacity: 1, x: 0, y: 0 },
    exit: { opacity: 0, x: 0, y: 10 },
  };

  return (
    <Box
      component={motion.section}
      sx={{
        width: { xxs: "80vw", xs: "85vw", sm: "60vw", md: "58vw", lg: "35vw" },
        minWidth: "250px", // 270px
        maxWidth: "60ch",
        margin: "auto",
        marginTop: "2.25rem",
      }}
      className={className}
      key="section"
      // initial={{ opacity: 0, x: 0, y: 10 }}
      // animate={{ opacity: 1, x: 0, y: 0 }}
      // exit={{ opacity: 0, x: 0, y: 10 }}
      initial="hidden"
      animate="enter"
      exit="exit"
      variants={motionVariants}
      transition={{ duration: 0.4, type: "easeInOut", delay: delay }}
      style={style}
    >
      {children}
    </Box>
  );
}

export default Section;
