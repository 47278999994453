import React from "react";
// Import React Router
import { Link } from "react-router-dom";
// Import React Image
import { useImage } from "react-image";
// Import Material UI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// Import Custom UI
import StyledCard from "../../ui/StyledCard";

function Post(props) {
  const { author, date, image, isFeatured, title } = props.post;
  const slug = replaceInvalidChar(props.post.slug);
  const { theme } = props;

  // Images
  const defaultImgThumbnail = "../../../img/default_img.svg";
  const { src } = useImage({
    srcList: [image, defaultImgThumbnail],
  });

  // Methods
  function replaceInvalidChar(input) {
    const replaceHashtag = "%23";
    const replaceSpace = "%20";
    let validString;

    validString = input.replace("#", replaceHashtag).replace(" ", replaceSpace);

    return validString;
  }

  return (
    <Grid item xxs={6}>
      <Link
        to={`../post/${slug}`}
        style={{ textDecoration: "none" }}
        preventScrollReset={true}
      >
        <StyledCard>
          <Box
            component="img"
            loading="lazy"
            src={src}
            alt={title}
            sx={{
              height: "100%",
              width: "100%",
              aspectRatio: "16 / 9",
              borderRadius: "15px",
            }}
          />
          <Typography
            component="h3"
            sx={{
              fontSize: "1.25rem",
              fontWeight: "400",
              mt: "0.5rem",
              color:
                theme.palette.mode === "dark"
                  ? "customWhite.high"
                  : "neutral.900",
            }}
          >
            {title}
          </Typography>
          <Box
            component="div"
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Typography
              component="p"
              sx={{
                fontSize: "0.875rem",
                color:
                  theme.palette.mode === "dark" ? "neutral.450" : "neutral.500",
                flex: 1,
                textAlign: "left",
                paddingLeft: "0.15rem",
              }}
            >
              {author || "Anonymous"}
            </Typography>

            <Typography
              component="p"
              sx={{
                fontSize: "0.875rem",
                color:
                  theme.palette.mode === "dark" ? "neutral.450" : "neutral.500",
                flex: 1,
                textAlign: "right",
                paddingRight: "0.15rem",
              }}
            >
              {date}
            </Typography>
          </Box>
        </StyledCard>
      </Link>
    </Grid>
  );
}

export default Post;
