import React from "react";
// Import React Router
import { Link } from "react-router-dom";
// Import Material UI
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
// Import Framer Motion
import { motion } from "framer-motion";
// Import Own Components
import Section from "./ui/Section";

function NotFoundPage() {
  const theme = useTheme();

  return (
    <Section>
      <Typography
        component="h2"
        sx={{
          width: "max-content",
          fontSize: {
            xs: "2rem",
            sm: "2.25rem",
            md: "2.75rem",
            lg: "3rem",
          },
          fontWeight: "600",
          //   marginBottom: "0.5rem",
          letterSpacing: "5px",
        }}
      >
        404
      </Typography>

      <Typography
        component="h3"
        sx={{
          fontSize: {
            xs: "1.8rem",
            sm: "2.05rem",
            md: "2.55rem",
            lg: "1.4rem",
          },
          marginBottom: "1rem",
        }}
      >
        Ooops!
        <br />
        Page Not Found
      </Typography>

      <Typography
        component="p"
        sx={{
          color:
            theme.palette.mode === "dark" ? "customWhite.low" : "neutral.350",
        }}
      >
        This page doesn't exist or was removed!
        <br /> We suggest you back to home
      </Typography>

      <Link to="/" style={{ textDecoration: "none" }}>
        <Button
          variant="contained"
          sx={{ borderRadius: "8px", marginTop: "2rem" }}
          component={motion.div}
          animate={{ scale: 1 }}
          whileTap={{ scale: 0.9 }}
        >
          Back to home
        </Button>
      </Link>
    </Section>
  );
}

export default NotFoundPage;
