import React from "react";
// Import React Router
import { Link } from "react-router-dom"; // Import React Image
import { useImage } from "react-image";
// Import Material UI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// Import Custom UI
import StyledCard from "../../ui/StyledCard";

function Project({ desc, href, title, thumbnail, theme }) {
  // Images
  const defaultImgThumbnail = "../../../img/default_img.svg";
  const { src } = useImage({
    srcList: [thumbnail, defaultImgThumbnail],
  });

  return (
    <Grid item xxs={6}>
      <Link
        style={{ textDecoration: "none" }}
        target="mynewtab"
        preventScrollReset={true}
        onClick={() => {
          window.location.href = href;
        }}
      >
        <StyledCard>
          <Box
            component="img"
            src={src}
            alt={title}
            sx={{
              height: "100%",
              width: "100%",
              aspectRatio: "16 / 9",
              borderRadius: "15px",
            }}
          />
          <Typography
            component="h3"
            sx={{
              fontSize: "1.25rem",
              fontWeight: "400",
              mt: "0.5rem",
              color:
                theme.palette.mode === "dark"
                  ? "customWhite.high"
                  : "neutral.900",
            }}
          >
            {title}
          </Typography>
          <Typography
            component="p"
            sx={{
              fontSize: "0.875rem",
              color:
                theme.palette.mode === "dark" ? "neutral.450" : "neutral.500",
            }}
          >
            {desc}
          </Typography>
        </StyledCard>
      </Link>
    </Grid>
  );
}

export default Project;
