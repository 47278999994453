import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// Import Context
import AppStatusContext, {
  AppStatusContextProvider,
} from "../../contexts/AppStatusContext";

function Admin() {
  // Context
  const navigate = useNavigate();
  // State
  const [isLogged, setIsLogged] = useState(false);

  const appStatusCtx = useContext(AppStatusContext);

  function changeAppStatus(e) {
    appStatusCtx.setAppStatus(e.target.name);

    navigate("/");
  }

  useEffect(() => {
    if (!isLogged) {
      navigate("/");
    }
  }, [isLogged, navigate]);

  return (
    <section>
      <h1>Admin</h1>

      <div>
        <h2>Current Status is: {appStatusCtx.appStatus}</h2>

        <button onClick={changeAppStatus} name="coming soon">
          Change Coming Soon
        </button>
        <button onClick={changeAppStatus} name="maintainance">
          Change Maintainance
        </button>
        <button onClick={changeAppStatus} name="normal">
          Change Normal
        </button>
      </div>
    </section>
  );
}

export default Admin;
