// Import React Components
import { createContext, useState } from "react";

const AppStatusContext = createContext({
  appStatus: "normal",
  setAppStatus: () => {},
});

export function AppStatusContextProvider(props) {
  const [appStatus, setAppStatus] = useState("normal");

  const context = {
    appStatus: appStatus,
    setAppStatus: setAppStatus,
  };

  return (
    <AppStatusContext.Provider value={context}>
      {props.children}
    </AppStatusContext.Provider>
  );
}

export default AppStatusContext;
