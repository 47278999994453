import React from "react";
// Import MUI Components
import Typography from "@mui/material/Typography";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import useTheme from "@mui/material/styles/useTheme";
// Import Styles
import classes from "./bioRow.module.css";

function BioRow({ year, desc }) {
  const theme = useTheme();

  return (
    <TimelineItem className={classes.timelineItem}>
      <TimelineSeparator>
        <TimelineDot />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Typography
          component="h2"
          sx={{
            marginRight: "1.5rem",
            marginBottom: "0.1rem",
            fontWeight: "600",
            fontSize: "1rem",
            color: theme.palette.mode === "dark" ? "neutral.100" : "",
          }}
        >
          {year}
        </Typography>
        <Typography
          component="p"
          sx={{
            flexGrow: "1",
            wordBreak: "break-word",
            fontWeight: "400",
            color: theme.palette.mode === "dark" ? "neutral.450" : "",
            fontSize: "1rem",
          }}
        >
          {desc}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
}

export default BioRow;
